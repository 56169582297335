import {
    GoogleMap,
    InfoWindow,
    KmlLayer,
    Marker,
    Polygon,
    StandaloneSearchBox,
    useLoadScript,
} from '@react-google-maps/api'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../stores/store'
//import { BlockButtonHeader } from '../BlockButtonHeader/BlockButtonHeader'
import { FormSendData } from '../FormSendData/FormSendData'
import { SupportContact } from '../SupportContact/SupportContact'
import disoftMarker from '../../assets/img/dianet_marker2.png'
import './index.css'
//import { CheckFormConnect } from '../FormSendData/CheckFormConnect'
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url'
import { CoverDistrict } from '../CoverDistrict/CoverDistrict'
import { cities } from '../CoverDistrict/constantsDistricts'
import intl from 'react-intl-universal'
import { useParams } from 'react-router'
import Loader from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import { google_key } from '../../API'

const containerStyle = {
    width: '100%',
    height: '100vh',
}

const options = {
    fillColor: '#0D92E2',
    strokeColor: '#0D92E2',
    fillOpacity: 0.4,
    strokeOpacity: 1,
    strokeWeight: 1,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
}

const libraries: Libraries = ['places']

export const MyCover = observer(() => {
    let { lang, lat, lan, city, isModal } = useParams<{
        lang: string
        lat: string
        lan: string
        city: string
        isModal: string
    }>()

    let lang_google_map = localStorage.getItem('lang')

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: google_key!,
        language: lang_google_map || 'ru',
        libraries,
        region: 'UA'
    })
    const { mapStore } = useStore()
    const mapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: true,
        zoomControlOptions: { position: 8, style: 2 },
        gestureHandling: isModal ? 'greedy' : 'cooperative'
    }

    const [openForm, setOpenForm] = useState<boolean>(false)
    //const [openCheckForm, setOpenCheckForm] = useState<boolean>(false)
    const [latLng, setLatLng] = useState<{ lat: number; lng: number }>()
    const [openMarkerInfo, setOpenMarkerInfo] = useState<number>(0)
    const [centerMap, setCenterMap] = useState<{ lat: number; lng: number }>({
        lat: 47.8319077,
        lng: 35.1204433,
    })
    const [zoom, setZoom] = useState<number>(12.1)
    const [address, setAddress] = useState<string>(mapStore?._addressGoogle)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //const [showHeader, setShowHeader] = useState<boolean>(false)
    const [buildings, changeBuildings] = useState<Array<Array<{ lat: number; lng: number }>>>(
        mapStore ? mapStore._poligonArray : []
    )

    const [bounds, setBounds] = useState<undefined | google.maps.LatLngBounds>(undefined)




    useEffect(() => {
        setAddress(mapStore?._addressGoogle)

        if (lang) {
            localStorage.setItem('lang', lang)
            if (Number(lat) && Number(lan)) {
                window.location.href = `${isModal ? '/modal' : ''}/${lat}/${lan}`
            } else if (city) {
                window.location.href = `${isModal ? '/modal' : ''}/${city}`
            } else {
                window.location.href = `${isModal ? '/modal' : ''}/`
            }
        }
        const upLoadData = async () => {
            await mapStore!.getCordPoligons()
            changeBuildings(mapStore._poligonArray)
        }
        if (mapStore.isLK) {
            setCenterMap({ lat: 47.836142, lng: 35.14635499999999 })
            setZoom(11.5)
        }
        upLoadData()

        //moving to specified city
        if (city) {
            const districtIndex = cities.findIndex((location) => location.name === city)
            if (districtIndex !== -1) {
                setCenterMap(cities[districtIndex].polygon)
                setLatLng(cities[districtIndex].polygon)
                setZoom(cities[districtIndex].zoom)
            }
        }

        //moving to specified coordinates
        else if (Number(lat) && Number(lan)) {
            setCenterMap({ lat: Number(lat), lng: Number(lan) })
            setZoom(16)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapStore?._addressGoogle])

    const handleClickMarker = (type: number) => {
        setOpenMarkerInfo(type)
    }

    const onLoadMap = () => {
        const bound = new window.google.maps.LatLngBounds()
        bound.extend(new window.google.maps.LatLng(47.957250, 35.709577))
        bound.extend(new window.google.maps.LatLng(47.657411, 34.887067))
        setBounds(bound)
    }

    const onClickMaps = async (e: any) => {
        if (!mapStore.isLK) {
            let latLng = { lat: e.latLng.lat(), lng: e.latLng.lng() }
            setLatLng(latLng)
            await mapStore!.getAddressGoogle(latLng.lat.toString(), latLng.lng.toString())
            setOpenForm(true)
        }
    }

    let SearchBox = useRef(null)

    const onLoad = (ref: any) => {
        SearchBox.current = ref
    }

    const onPlacesChanged = async () => {
        let searchMap: any = SearchBox.current
        let pos = {
            lat: 0,
            lng: 0,
        }
        let place = searchMap.getPlaces()[0]
        if (searchMap) {
            pos.lat = place.geometry.location.lat()
            pos.lng = place.geometry.location.lng()
        }
        setZoom(8)
        setLatLng(pos)
        setCenterMap(pos)
        setZoom(18)
        await mapStore!.getAddressGoogle(pos.lat.toString(), pos.lng.toString())
    }

    const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAddress(e?.target?.value)
    }

    const renderMarker = (pos: { lat: number | null; lng: number | null }) => {
        if (pos.lat === null || pos.lng === null) return
        // @ts-ignore
        return <Marker position={pos} title={'Marker'} onClick={mapMarkerClick}></Marker>
    }

    const mapMarkerClick = async () => {
        await mapStore!.getAddressGoogle(latLng!.lat.toString(), latLng!.lng.toString())
        setOpenForm(true)
    }

    const centerDistrict = (pos: { lat: number; lng: number }) => {
        setCenterMap(pos)
    }
    const RenderMap = () => {
        return (
            <>
                {/* {showHeader && <BlockButtonHeader />} */}
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={centerMap}
                    zoom={zoom}
                    onLoad={onLoadMap}
                    options={mapOptions}
                    onClick={onClickMaps}>
                    {!mapStore.isLK && (
                        <StandaloneSearchBox
                            onLoad={onLoad}
                            onPlacesChanged={onPlacesChanged}
                            bounds={bounds}>
                            <input
                                type='text'
                                placeholder={intl.get('Введите адрес')}
                                className='searchGoogleMap'
                                value={address}
                                onChange={onChangeAddress}
                            />
                        </StandaloneSearchBox>
                    )}
                    {buildings.length > 0 &&
                        buildings.map((polygon, index) => (
                            <Polygon
                                key={`polygon-${index}`}
                                path={polygon}
                                options={options}
                                onClick={onClickMaps}
                            />
                        ))}

                    {/* {mapStore?._districPoligon.length > 0 &&
                        mapStore?._districPoligon.map((polygon, index) => (
                            <Polygon
                                key={`polygon-${index}`}
                                path={polygon.polygon}
                                options={{
                                    fillColor: `#${polygon.color}`,
                                    strokeColor: `#${polygon.color}`,
                                    fillOpacity: 0.4,
                                    strokeOpacity: 1,
                                    strokeWeight: 1,
                                    draggable: false,
                                    editable: false,
                                    geodesic: false,
                                    zIndex: 1,
                                }}
                                //onClick={onClickPoligon}
                            />
                        ))} */}
                    <Marker
                        position={{ lat: 47.781172, lng: 35.20665499999999 }}
                        title={'DIANET ISP'}
                        onClick={() => handleClickMarker(1)}
                        icon={{
                            url: disoftMarker, // url
                            //@ts-ignore
                            scaledSize: new window.google.maps.Size(40, 64), // scaled size
                        }}>
                        {openMarkerInfo === 1 && (
                            <InfoWindow
                                position={{ lat: 47.781142, lng: 35.20635499999999 }}
                                onCloseClick={() => handleClickMarker(0)}>
                                <div>
                                    <h4>DIANET ISP</h4>
                                    <div>
                                        <strong>Dianet ISP</strong> -{' '}
                                        {intl.get('интернет-провйдер в городе Запорожье')}
                                    </div>
                                    <div>{intl.get('График работы: ежедневно 8:00 - 18:00')}</div>
                                    <div>
                                        {intl.get('Техническая поддержка работает круглосуточно')}
                                    </div>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                    <Marker
                        position={{ lat: 47.87595, lng: 35.062208 }}
                        title={'DIANET ISP'}
                        onClick={() => handleClickMarker(2)}
                        icon={{
                            url: disoftMarker, // url
                            //@ts-ignore
                            scaledSize: new window.google.maps.Size(40, 64), // scaled size
                        }}>
                        {openMarkerInfo === 2 && (
                            <InfoWindow
                                position={{ lat: 47.87595, lng: 35.062208 }}
                                onCloseClick={() => handleClickMarker(0)}>
                                <div>
                                    <h4>DIANET ISP</h4>
                                    <div>
                                        <strong>Dianet ISP</strong> -{' '}
                                        {intl.get('интернет-провйдер в городе Запорожье')}
                                    </div>
                                    <div>{intl.get('График работы: ежедневно 8:00 - 18:00')}</div>
                                    <div>
                                        {intl.get('Техническая поддержка работает круглосуточно')}
                                    </div>
                                </div>
                            </InfoWindow>
                        )}
                    </Marker>
                    {latLng && renderMarker(latLng)}
                    <KmlLayer
                        onLoad={() => {
                            // if (e) {
                            //     console.log('e', e)
                            // }
                        }}
                        options={{ suppressInfoWindows: false, preserveViewport: true }}
                        onClick={onClickMaps}
                        onUnmount={() => {}}
                        url='https://map.dianet.online/dianet_02_01_2025.kml'
                    />
                </GoogleMap>
                {openForm && (
                    <FormSendData
                        closeForm={() => setOpenForm(false)}
                        latLng={latLng!}
                        addressLocation={mapStore!._addressGoogle}
                    />
                )}
                {/* {openCheckForm && (
                    <CheckFormConnect
                        closeForm={() => setOpenCheckForm(false)}
                        latLng={latLng!}
                        addressLocation={mapStore!._addressGoogle}
                    />
                )} */}

                {!mapStore.isLK && (
                    <>
                        <CoverDistrict
                            centerDistrict={centerDistrict}
                            setZoom={setZoom}
                        />
                        <SupportContact />
                    </>
                )}
            </>
        )
    }
    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    return isLoaded ? (
        RenderMap()
    ) : (
        <div className='spinnerContainer'>
            <Loader type='Puff' color='#FF6600' height={100} width={100} />
        </div>
    )
})
